import { css } from '@emotion/react'
import { fliperFontFaces } from './theme'

const globalStyles = css`
  ${fliperFontFaces}

  a {
    text-decoration: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .underline {
    color: inherit;
    text-decoration: underline;
  }

  /* Custom info snackbar styling */
  .SnackbarContent-root {
    background-color: #ecf8fa !important;
    color: #121f3d !important;
    border: 1px solid #121f3d !important;
    box-shadow: none !important;
  }
  /* Custom success snackbar styling */
  .success-snackbar {
    background-color: #add6dd !important;
    color: #121f3d !important;
    border: 1px solid #add6dd !important;
  }
  /* Custom error snackbar styling */
  .error-snackbar {
    background-color: #cf214d !important;
    color: #fff !important;
    border: 1px solid #cf214d !important;
  }
`

export default globalStyles
