import CountryRepository from 'utils/CountryRepository'

/**
 * Used for differentiating initial create and edit quiz actions. Different
 * forms are used and different actions are dispatched depending on the used
 * mode.
 * @constant
 * @name MODE
 */
export const MODE = {
  CREATE: 0,
  EDIT: 1
}
export const MINIMUM_QUIZ_VALUE = 300

/**
 * Options for start page dropdown, used in CreateQuizForm and
 * UpdateQuizForm. Please remember that `t` instance has to have `Common`
 * namespace in scope.
 * @constant
 * @name START_PAGE_OPTIONS
 */
export const START_PAGE_OPTIONS = t => [
  {
    label: t('Common:yes'),
    value: 'true'
  },
  { label: t('Common:no'), value: 'false' }
]

export const STATUS_OPTIONS = t => [
  { value: 'true', label: t('UpdateQuizForm:statusOptions.active') },
  { value: 'false', label: t('UpdateQuizForm:statusOptions.inactive') }
]

export const SCORE_ACTIVE_OPTIONS = t => [
  { label: t('Common:on'), value: 'true' },
  { label: t('Common:off'), value: 'false' }
]

export const QUIZ_TYPE = {
  REGULAR: 'Regular',
  ONE_QUESTION_PER_PAGE: 'One question per page'
}

export const QUIZ_TYPE_OPTIONS = t => [
  { label: t('Common:projectType.regular'), value: QUIZ_TYPE.REGULAR },
  {
    label: t('Common:projectType.oneQuestionPerPage'),
    value: QUIZ_TYPE.ONE_QUESTION_PER_PAGE
  }
]

export const OPT_IN_TYPES = {
  SALUTATION: 'salutation',
  TITLE: 'title',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  COMPANY: 'companyName',
  AGE: 'age',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  PLACE: 'place',
  COUNTRY: 'country',
  WEBSITE: 'website',
  SOCIAL_MEDIA: 'socialMediaProfile',
  PHONE: 'phone',
  EMAIL: 'email',
  CUSTOM1: 'custom1',
  CUSTOM2: 'custom2'
}

export const OPT_IN_TYPES_WITH_CUSTOM = (custom1, custom2) => {
  return {
    SALUTATION: 'salutation',
    TITLE: 'title',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY: 'companyName',
    AGE: 'age',
    DATE_OF_BIRTH: 'dateOfBirth',
    GENDER: 'gender',
    ADDRESS: 'address',
    POSTAL_CODE: 'postalCode',
    PLACE: 'place',
    COUNTRY: 'country',
    WEBSITE: 'website',
    SOCIAL_MEDIA: 'socialMediaProfile',
    PHONE: 'phone',
    CUSTOM1: custom1 || 'custom1',
    CUSTOM2: custom2 || 'custom2',
    EMAIL: 'email'
  }
}

export const CONSTANT_OPT_IN_TYPES = {
  SALUTATION: 'salutation',
  TITLE: 'title',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  COMPANY: 'companyName',
  AGE: 'age',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  PLACE: 'place',
  COUNTRY: 'country',
  WEBSITE: 'website',
  SOCIAL_MEDIA: 'socialMediaProfile',
  PHONE: 'phone',
  EMAIL: 'email'
}

export const CONTACT_DETAILS_ORDER = [
  'salutation',
  'title',
  'firstName',
  'lastName',
  'companyName',
  'age',
  'dateOfBirth',
  'gender',
  'line1',
  'postalCode',
  'place',
  'country',
  'website',
  'socialMediaProfile',
  'phone',
  'email'
]

/**
 * Matrix for setting object order in `informationToGather` array. Important:
 * when creating your own matrices, make sure that indices are unique.
 * @constant
 * @name OPT_IN_TYPE_ORDER
 * Added: Tue, 02 Feb 2021 18:39:48 +0100
 */
export const OPT_IN_TYPE_ORDER = Object.entries(OPT_IN_TYPES).reduce(
  (prev, [_, value], idx) => {
    prev[[value]] = idx

    return prev
  },
  {}
)

export const OPT_IN_TYPE_ORDER_WITH_CUSTOM = (custom1, custom2) => {
  const OptInTypes = OPT_IN_TYPES_WITH_CUSTOM(custom1, custom2)
  return Object.entries(OptInTypes).reduce((prev, [_, value], idx) => {
    prev[[value]] = idx

    return prev
  }, {})
}

export const OPT_IN_OPTIONS = Object.values(OPT_IN_TYPES)

export const BASIC_OPT_IN_OPTIONS = [
  OPT_IN_TYPES.AGE,
  OPT_IN_TYPES.COUNTRY,
  OPT_IN_TYPES.GENDER,
  OPT_IN_TYPES.PHONE
]

export const OPT_IN_ENTRIES = (t, custom1, custom2) => [
  { value: OPT_IN_TYPES.EMAIL, label: t('optIn.email') },
  { value: OPT_IN_TYPES.SALUTATION, label: t('optIn.salutation') },
  { value: OPT_IN_TYPES.TITLE, label: t('optIn.title') },
  { value: OPT_IN_TYPES.FIRST_NAME, label: t('optIn.firstName') },
  { value: OPT_IN_TYPES.LAST_NAME, label: t('optIn.lastName') },
  { value: OPT_IN_TYPES.COMPANY, label: t('optIn.company') },
  { value: OPT_IN_TYPES.AGE, label: t('optIn.age') },
  { value: OPT_IN_TYPES.DATE_OF_BIRTH, label: t('optIn.dateOfBirth') },
  { value: OPT_IN_TYPES.GENDER, label: t('optIn.gender') },
  { value: OPT_IN_TYPES.ADDRESS, label: t('optIn.address') },
  { value: OPT_IN_TYPES.POSTAL_CODE, label: t('optIn.postalCode') },
  { value: OPT_IN_TYPES.PLACE, label: t('optIn.place') },
  { value: OPT_IN_TYPES.COUNTRY, label: t('optIn.country') },
  { value: OPT_IN_TYPES.WEBSITE, label: t('optIn.website') },
  { value: OPT_IN_TYPES.SOCIAL_MEDIA, label: t('optIn.socialMediaProfile') },
  { value: OPT_IN_TYPES.PHONE, label: t('optIn.phone') },
  {
    value: custom1 || OPT_IN_TYPES.CUSTOM1,
    label: custom1 || t('optIn.custom')
  },
  {
    value: custom2 || OPT_IN_TYPES.CUSTOM2,
    label: custom2 || t('optIn.custom')
  }
]

export const AGREEMENT_NAME_PREFIX = 'agreement-'

export const ALWAYS_ON_OPT_IN_OPTIONS = ['email']

export const REQUIRED_PAID_OPT_IN_OPTIONS = [
  'address',
  'postalCode',
  'country',
  'firstName',
  'lastName',
  'email'
]

export const DELIVERY_METHOD = {
  AFTER: 'After time',
  AT: 'At time',
  NONE: 'None'
}

export const VALIDATION_STATUS = {
  INITIAL: 'INITIAL',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

export const ENCRYPTION_METHOD = {
  START_TLS: 'StartTLS',
  SSL_TLS: 'SSL/TLS',
  UNENCRYPTED: 'Unencrypted'
}

export const ENCRYPTION_OPTIONS = t => [
  {
    value: ENCRYPTION_METHOD.START_TLS,
    label: t('encryptionMethod.startTLS')
  },
  {
    value: ENCRYPTION_METHOD.SSL_TLS,
    label: t('encryptionMethod.SSLTLS')
  },
  {
    value: ENCRYPTION_METHOD.UNENCRYPTED,
    label: t('encryptionMethod.unencrypted')
  }
]

export const EMAIL_PROVIDER = {
  ACTIVE_CAMPAIGN: 'activeCampaign',
  GET_RESOINSE: 'getResponse',
  KLICK_TIPP: 'klickTipp',
  MAILCHIMP: 'mailChimp',
  QUENTN: 'quentn',
  BREVO: 'brevo'
}

export const EMAIL_PROVIDER_OPTIONS = t => [
  {
    value: '',
    label: t('label.noneProvider')
  },
  {
    value: EMAIL_PROVIDER.ACTIVE_CAMPAIGN,
    label: 'Active Campaign'
  },
  {
    value: EMAIL_PROVIDER.GET_RESOINSE,
    label: 'Get Response'
  },
  {
    value: EMAIL_PROVIDER.KLICK_TIPP,
    label: 'Klick-Tipp'
  },
  {
    value: EMAIL_PROVIDER.MAILCHIMP,
    label: 'MailChimp'
  },
  {
    value: EMAIL_PROVIDER.QUENTN,
    label: 'Quentn'
  },
  {
    value: EMAIL_PROVIDER.BREVO,
    label: 'Brevo'
  }
]

export const DELIVERY_METHOD_OPTIONS = t => [
  {
    value: DELIVERY_METHOD.AFTER,
    label: t('deliveryLabel.afterAmountOfTime')
  },
  {
    value: DELIVERY_METHOD.AT,
    label: t('deliveryLabel.atCertainTime')
  },
  {
    value: DELIVERY_METHOD.NONE,
    label: t('deliveryLabel.None')
  }
]

export const SUBJECTS = t => [
  {
    label: t('subject.help'),
    value: 'Help'
  },
  {
    label: t('subject.idea'),
    value: 'Idea'
  },
  {
    label: t('subject.feedback'),
    value: 'Feedback'
  },
  {
    label: t('subject.bug'),
    value: 'Bug'
  },
  {
    label: t('subject.other'),
    value: 'Other'
  }
]

export const DEFAULT_QUIZ_FONT = 'Montserrat'

export const IS_PAYABLE_OPTIONS = t => [
  {
    label: t('Common:yes'),
    value: 'true'
  },
  { label: t('Common:no'), value: 'false' }
]

export const QUESTION_TYPES = {
  BUTTON: 'Button',
  CHECKBOX: 'Checkbox',
  DATE: 'Date',
  GROUPED: 'Grouped',
  MULTIPLE: 'Multiple',
  RADIO: 'Radio',
  TEXT: 'Text',
  VALUATION: 'Valuation',
  FILE: 'File',
  BUTTON_WITH_IMAGE: 'ButtonWithImage',
  DRAW: 'Draw'
}

export const QUESTION_TYPES_WITHOUT_ANSWER_ID = {
  DATE: 'Date',
  TEXT: 'Text',
  FILE: 'File',
  DRAW: 'Draw'
}

export const QUESTION_TYPE_SHORT_NAMES = t => [
  { type: QUESTION_TYPES.BUTTON, label: t('questionTypeShortName.button') },
  { type: QUESTION_TYPES.CHECKBOX, label: t('questionTypeShortName.checkbox') },
  { type: QUESTION_TYPES.MULTIPLE, label: t('questionTypeShortName.multiple') },
  { type: QUESTION_TYPES.RADIO, label: t('questionTypeShortName.radio') },
  { type: QUESTION_TYPES.DATE, label: t('questionTypeShortName.date') },
  { type: QUESTION_TYPES.TEXT, label: t('questionTypeShortName.text') },
  {
    type: QUESTION_TYPES.VALUATION,
    label: t('questionTypeShortName.valuation')
  },
  { type: QUESTION_TYPES.GROUPED, label: t('questionTypeShortName.grouped') },
  { type: QUESTION_TYPES.FILE, label: t('questionTypeShortName.file') },
  {
    type: QUESTION_TYPES.BUTTON_WITH_IMAGE,
    label: t('questionTypeShortName.buttonWithImage')
  },
  { type: QUESTION_TYPES.DRAW, label: t('questionTypeShortName.drawing') }
]

export const QUESTION_TYPE_OPTIONS = t => [
  { value: QUESTION_TYPES.BUTTON, label: t('questionType.button') },
  {
    value: QUESTION_TYPES.BUTTON_WITH_IMAGE,
    label: t('questionType.buttonWithImage')
  },
  { value: QUESTION_TYPES.CHECKBOX, label: t('questionType.checkbox') },
  { value: QUESTION_TYPES.MULTIPLE, label: t('questionType.multiple') },
  { value: QUESTION_TYPES.RADIO, label: t('questionType.radio') },
  { value: QUESTION_TYPES.GROUPED, label: t('questionType.grouped') },
  { value: QUESTION_TYPES.DATE, label: t('questionType.date') },
  { value: QUESTION_TYPES.TEXT, label: t('questionType.text') },
  { value: QUESTION_TYPES.VALUATION, label: t('questionType.valuation') },
  { value: QUESTION_TYPES.FILE, label: t('questionType.file') },
  { value: QUESTION_TYPES.DRAW, label: t('questionType.drawing') }
]

export const QUESTION_TYPE_OPTIONS_SCORE_ACTIVE = t => [
  { value: QUESTION_TYPES.BUTTON, label: t('questionType.button') },
  {
    value: QUESTION_TYPES.BUTTON_WITH_IMAGE,
    label: t('questionType.buttonWithImage')
  },
  { value: QUESTION_TYPES.CHECKBOX, label: t('questionType.checkbox') },
  { value: QUESTION_TYPES.MULTIPLE, label: t('questionType.multiple') },
  { value: QUESTION_TYPES.RADIO, label: t('questionType.radio') },
  { value: QUESTION_TYPES.GROUPED, label: t('questionType.grouped') },
  { value: QUESTION_TYPES.TEXT, label: t('questionType.text') },
  { value: QUESTION_TYPES.DATE, label: t('questionType.date') },
  { value: QUESTION_TYPES.VALUATION, label: t('questionType.valuation') }
]

export const TEXT_QUESTION_MIN_ROWS = {
  SINGLE: 1,
  FOUR: 4,
  EIGHT: 8,
  SIXTEEN: 16
}

export const TEXT_QUESTION_MIN_ROWS_OPTIONS = t => [
  { value: TEXT_QUESTION_MIN_ROWS.SINGLE, label: t('textQuestion.single') },
  { value: TEXT_QUESTION_MIN_ROWS.FOUR, label: '4' },
  { value: TEXT_QUESTION_MIN_ROWS.EIGHT, label: '8' },
  { value: TEXT_QUESTION_MIN_ROWS.SIXTEEN, label: '16' }
]

export const VALUATION_QUESTION_MAX_OPTIONS = 10

export const TIMELINE_BUTTONS_DESIGN = {
  ROUND: 'Round',
  SQUARE: 'Square'
}

export const NEXT_BUTTON_WIDTH = {
  NORMAL: 'normal',
  FULL_WIDTH: 'fullWidth'
}

export const EMPTY_SELECT_VALUE = '#META#EMPTY_SELECT_VALUE#METAEND#'

export const ANSWER_SELECT_OPTIONS = ({ t, answers }) => [
  { value: EMPTY_SELECT_VALUE, label: t('Common:selectPleaseChoose') },
  ...answers.map((answer, idx) => ({
    value: answer._id,
    label: answer.content
  }))
]

export const COUNTRY_OPTIONS = t => [
  { value: EMPTY_SELECT_VALUE, label: t('Common:selectPleaseChoose') },
  ...CountryRepository.getOptions()
]

export const CURRENCY_OPTIONS = [
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' }
]

export const GENDER_TYPES = {
  FEMALE: 'Female',
  MALE: 'Male',
  OTHER: 'Other'
}

export const GENDER_OPTIONS = t => [
  { value: EMPTY_SELECT_VALUE, label: t('Common:selectPleaseChoose') },
  { value: 'Female', label: t('label.genderOptions.female') },
  { value: 'Male', label: t('label.genderOptions.male') },
  { value: 'Other', label: t('label.genderOptions.other') }
]

/**
 * Note: Some salutation types are not used at the moment, they will most
 * probably used in the future.
 */

export const SALUTATION_TYPES = {
  MR: 'Mr.',
  MS: 'Ms.'
  // MRS: 'Mrs.',
  // IND: 'Ind.'
}

export const SALUTATION_OPTIONS = t => [
  { value: EMPTY_SELECT_VALUE, label: t('Common:selectPleaseChoose') },
  { value: 'Mr.', label: t('label.salutationOptions.mr') },
  { value: 'Ms.', label: t('label.salutationOptions.ms') }
  // { value: 'Mrs.', label: t('label.salutationOptions.mrs') },
  // { value: 'Ind.', label: t('label.salutationOptions.individual') }
]

export const COPY_TYPE = {
  PAYMENT: 'PAYMENT',
  PREVIEW: 'PREVIEW',
  VIEW: 'VIEW',
  HTML: 'HTML'
}

export const TEST_RESPONDENT = 'TEST_RESPONDENT'

export const EMAIL_RECEIVER = {
  VENDOR: 'VENDOR',
  RESPONDENT: 'RESPONDENT',
  SENDER: 'SENDER'
}

export const RESPONDENT_DOCUMENT = {
  INVOICE: 'INVOICE',
  REFUND: 'REFUND'
}

export const QUIZ_FLOW_DIRECTION = {
  NEXT: 'NEXT',
  BACK: 'BACK'
}

export const USER_CURRENCY = {
  USD: 'USD',
  EUR: 'EUR'
}

export const RESET_STATISTIC = {
  CONVERSSION_RATE: 'CONVERSSION_RATE',
  EVALUATION: 'EVALUATION'
}

export const LOADING_STATE = {
  LOADING: 0,
  LOADED: 1,
  ERROR: 2,
  INITIAL: 'INITIAL',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

export const CONDITIONAL_LOGIC = {
  ANY_QUESTION: 'ANY_QUESTION',
  ANY_ANSWER: 'ANY_ANSWER',
  NEXT_PAGE: 'NEXT_PAGE',
  AFTER_LAST_PAGE: 'AFTER_LAST_PAGE'
}

export const DEFAULT_SCORE_RANGE = {
  minPoints: 0,
  maxPoints: 0,
  content: '',
  showContent: false
}

export const SCORE_ANALYSIS_TYPE = {
  total: 'total',
  category: 'category'
}

export const SCORE_ANALYSIS = {
  pages: [1],
  name: '',
  scoreRanges: [DEFAULT_SCORE_RANGE],
  isTotal: true
}

export const ALIGNMENT_TYPE = {
  LEFT: 'left',
  CENTER: 'center'
}
export const TIMELINE = {
  NONE: 'None',
  LINEAR: 'Linear',
  STEPPER: 'Stepper'
}
